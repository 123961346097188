import React from "react";


class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/woordenlijst/">Woordenlijst</a>
                    </li>
                    <li className="last">
                      <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li
                      id="uilist-hor nav-top nav-lang_nl-BE"
                      className="active"
                    >
                      <a href="/privacybeleid/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr">
                      <a href="/fr/respect-de-la-vie-privee/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="697fe0e08f3e5e0caf90c871b3511e33af4a6105"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/zoeken"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <h1 className="logo" style={{
                "margin-left": "3px"
              }}><a href="/">Imodium®</a>
                </h1>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/hoe-werkt-imodium-instant/">
                          Hoe werkt
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/imodium-behandelen-diarree/">
                          IMODIUM® <br />
                          Geneesmiddelen
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/feiten-over-diarree/">
                          Diarree - oorzaken
                          <br />
                          en behandeling
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/diarree-op-reis/">
                          Diarree
                          <br />
                          op reis
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/diarree-bij-kinderen/">
                          Diarree
                          <br />
                          bij kinderen
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/infomateriaal-diaree/">Infomateriaal</a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/privacybeleid/"> Privacybeleid</a>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-39">
            <h1>Privacybeleid</h1>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                widows: "0",
                orphans: "0"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Johnson &amp; Johnson Consumer N.V. is begaan met uw privacy en wil dat u vertrouwd bent met hoe wij informatie verzamelen, gebruiken en doorgeven. Dit Privacybeleid omschrijft onze praktijken in verband met de informatie die wij of onze dienstverleners verzamelen via de door ons beheerde website of applicatie (hierna de ‘Dienst’ genoemd) waar vandaan u dit Privacybeleid hebt bereikt. Door ons persoonsgegevens te verstrekken of door de Dienst te gebruiken, bevestigt u dat u dit Privacybeleid gelezen en begrepen heeft.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>GEBRUIK DOOR MINDERJARIGEN</b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  De Dienst is niet gericht op personen jonger dan 13 jaar. Wij vragen van deze personen geen persoonsgegevens via de Dienst te verstrekken. Als uw kind persoonsgegevens heeft ingediend en u wilt verzoeken dat dergelijke persoonsgegevens worden verwijderd, neem dan contact met ons op zoals onder ‘Contact met ons opnemen’ wordt uitgelegd.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>VERZAMELING VAN INFORMATIE</b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  We kunnen u vragen om persoonsgegevens te versturen zodat u kunt gebruikmaken van bepaalde functionaliteiten (zoals de nieuwsbrief, tips/suggesties, of de verwerking van uw bestelling) of om deel te nemen aan een bepaalde activiteit (zoals wedstrijden of andere promotionele acties). U zult worden geïnformeerd welke gegevens vereist zijn en welke optioneel zijn.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Wij kunnen de gegevens die u hebt verstrekt combineren met andere informatie die wij over u hebben verzameld, zowel on- als offline, inclusief bijvoorbeeld uw aankoopgeschiedenis. We kunnen ze ook combineren met informatie die wij over u krijgen van andere Johnson &amp; Johnson filialen. 
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Als u ons persoonsgegevens over een andere persoon verstrekt, verklaart u dat u daar de toelating voor heeft en dat u ons toelaat om die informatie te gebruiken in overeenstemming met dit Privacybeleid.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>GEVOELIGE INFORMATIE</b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                widows: "0",
                orphans: "0"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Behalve als wij het specifiek vereisen of verzoeken, vragen wij u ons geen gevoelige persoonsgegevens te verstrekken of aan ons bekend te maken (bijv. nationale identificatienummers, informatie met betrekking tot ras of etnische afkomst, politieke mening, religie of filosofische overtuiging, gezondheid of medische aandoeningen, seksueel gedrag of seksuele geaardheid, criminele achtergrond of lidmaatschap van een vakbond, of biometrische of genetische gegevens) op of via de Dienst of op andere wijze.{" "}
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>AUTOMATISCHE VERZAMELING EN GEBRUIK VAN INFORMATIE</b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Wij en onze dienstverleners kunnen bepaalde gegevens automatisch verzamelen terwijl u de Dienst bezoekt. 
                  Lees het{" "}
                    <a className="western" href="/cookiebeleid/">
                      Cookiebeleid
                    </a>{" "}
                    gedetailleerde informatie over de cookies en andere traceertechnologieën die we op de Dienst gebruiken. Tenzij anders toegelaten door de toepasselijke wetgeving, plaatsen we cookies nadat we jouw toestemming gekregen hebben via de cookiebanner of het voorkeurenmenu. Het Cookiebeleid omvat informatie over hoe u deze technologieën kunt uitschakelen.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Wij en onze dienstverleners kunnen ook automatisch informatie verzamelen en gebruiken, op de volgende manieren:
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "margin-left": "0.5in",
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>Via uw browser</b>
                  </span>
                </font>
              </font>
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  : Bepaalde informatie wordt door de meeste browsers verzameld, zoals uw Media Access Control (MAC)-adres, computertype (Windows of Mac), uw schermresolutie, de naam en versie van uw besturingssysteem en het type en de versie van uw internetbrowser. We kunnen soortgelijke informatie verzamelen, zoals het type van uw toestel en de unieke toestelidentificatie, als u via een mobiel toestel de Dienst gebruikt. We gebruiken deze informatie om te verzekeren dat de Dienst correct functioneert.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "margin-left": "0.5in",
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>IP-adres</b>
                  </span>
                </font>
              </font>
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  : Uw IP-adres is een getal dat automatisch door uw internetprovider aan uw computer wordt toegekend. Een IP-adres wordt automatisch geïdentificeerd en vastgelegd in onze serverlogbestanden telkens als een gebruiker de Dienst bezoekt, samen met de duur van het bezoek en de pagina's die zijn bezocht. Het verzamelen van IP-adressen is een standaardgebruik en wordt door veel online diensten automatisch gedaan. We gebruiken IP-adressen om de gebruiksniveaus van de Dienst te berekenen, om serverproblemen te diagnosticeren en om de Dienst te beheren. We kunnen ook uw locatie bij benadering afleiden uit uw IP-adres.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "margin-left": "0.5in",
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                widows: "0",
                orphans: "0"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>Apparaatinformatie</b>
                  </span>
                </font>
              </font>
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  : We kunnen informatie verzamelen over uw mobiele toestel, zoals een unieke toestelidentificatie, om te begrijpen hoe u gebruikt maakt van de Dienst.{" "}
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>HOE WE INFORMATIE GEBRUIKEN EN DOORZENDEN</b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  We gebruiken en geven de informatie die u aan ons hebt verstrekt door zoals het aan u wordt uitgelegd waar u de gegevens verstrekt. Zie het gedeelte ‘Keuzes en toegang’ hieronder om te weten te komen hoe u kunt kiezen om een bepaald gebruik of doorgifte te weigeren.{" "}
                  </span>
                </font>
              </font>
            </p>
            <p
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                widows: "2",
                orphans: "2"
              }}
            >
              <font color="#ff0000">
                <font face="Verdana, serif">
                  <font
                    size="2"
                    style={{
                      "font-size": "9pt"
                    }}
                  >
                    <font color="#00000a">
                      <span lang="nl-NL">
                      Waar vereist door de toepasselijke wetgeving zullen wij op het moment van de gegevensverzameling uw toestemming vragen voor ons gebruik van uw persoonsgegevens.  We kunnen, waar nodig, ook informatie van of over u gebruiken om een contract uit te voeren, om te voldoen aan een wettelijke verplichting (bijvoorbeeld voor onze verplichtingen inzake geneesmiddelenbewaking) of voor onze gerechtvaardigde zakelijke belangen.  We kunnen ons ook baseren op andere rechtsgrondslagen, met name voor:
                      </span>
                    </font>
                  </font>
                </font>
              </font>
            </p>
            <ul>
              <li>
                <p
                  className="western"
                  style={{
                    "-webkit-text-indent": "0in",
                    "text-indent": "0in",
                    "margin-bottom": "0.08in"
                  }}
                >
                  <a name="_DV_M159" />{" "}
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                        <b>
                          Het aanbieden van de functionaliteiten van de Dienst
                          en het voldoen aan uw verzoeken.
                        </b>
                      </span>
                    </font>
                  </font>
                </p>
                <ul>
                  <li>
                    <p
                      className="western"
                      style={{
                        "margin-bottom": "0.08in"
                      }}
                    >
                      <font face="Verdana, serif">
                        <font
                          size="2"
                          style={{
                            "font-size": "9pt"
                          }}
                        >
                          <span lang="nl-NL">
                            om u de functionaliteit van de dienst aan te bieden
                            en u de bijbehorende klantenservice te verlenen;
                          </span>
                        </font>
                      </font>
                    </p>
                  </li>
                  <li>
                    <p
                      className="western"
                      style={{
                        "margin-bottom": "0.08in"
                      }}
                    >
                      <font face="Verdana, serif">
                        <font
                          size="2"
                          style={{
                            "font-size": "9pt"
                          }}
                        >
                          <span lang="nl-NL">
                            om uw vragen te beantwoorden en aan uw verzoeken te
                            voldoen, zoals het verzenden van documenten waarom u
                            verzoekt of het sturen van e-mailherinneringen;
                          </span>
                        </font>
                      </font>
                    </p>
                  </li>
                  <li>
                    <p
                      className="western"
                      style={{
                        "margin-bottom": "0.08in"
                      }}
                    >
                      <a name="_DV_M160" />{" "}
                      <font face="Verdana, serif">
                        <font
                          size="2"
                          style={{
                            "font-size": "9pt"
                          }}
                        >
                          <span lang="nl-NL">
                            om u belangrijke informatie toe te sturen met
                            betrekking tot onze relatie met u of betreffende de
                            Dienst, of over wijzigingen in onze algemene
                            voorwaarden, beleidsregels en/of andere
                            administratieve informatie.
                          </span>
                        </font>
                      </font>
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
            <p
              className="western"
              lang="nl-BE"
              style={{
                "margin-left": "1in",
                "margin-bottom": "0.08in"
              }}
            >
              <br />
              &nbsp;
            </p>
            <p
              className="western"
              style={{
                "margin-left": "0.79in",
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    Wij zullen deze activiteiten uitvoeren om onze contractuele
                    relatie met u te beheren en/of om een wettelijke
                    verplichting na te komen.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              lang="nl-BE"
              style={{
                "margin-left": "1.25in",
                "margin-bottom": "0.08in"
              }}
            >
              <a name="_DV_M161" />
              <br />
              &nbsp;
            </p>
            <ul>
              <li>
                <p
                  className="western"
                  style={{
                    "-webkit-text-indent": "0in",
                    "text-indent": "0in",
                    "margin-bottom": "0.08in"
                  }}
                >
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                        <b>Het behartigen van onze zakelijke doeleinden.</b>
                      </span>
                    </font>
                  </font>
                </p>
                <ul>
                  <li>
                    <p
                      className="western"
                      style={{
                        "margin-bottom": "0.08in"
                      }}
                    >
                      <font face="Verdana, serif">
                        <font
                          size="2"
                          style={{
                            "font-size": "9pt"
                          }}
                        >
                          <span lang="nl-NL">
                            voor gegevensanalyse, bijvoorbeeld om de efficiëntie
                            van de Dienst te verbeteren;
                          </span>
                        </font>
                      </font>
                    </p>
                  </li>
                  <li>
                    <p
                      className="western"
                      style={{
                        "margin-bottom": "0.08in"
                      }}
                    >
                      <font face="Verdana, serif">
                        <font
                          size="2"
                          style={{
                            "font-size": "9pt"
                          }}
                        >
                          <span lang="nl-NL">
                            voor audits, om te controleren of onze interne
                            processen functioneren zoals bedoeld en te voldoen
                            aan juridische, wettelijke of contractuele
                            vereisten;
                          </span>
                        </font>
                      </font>
                    </p>
                  </li>
                  <li>
                    <p
                      className="western"
                      style={{
                        "margin-bottom": "0.08in"
                      }}
                    >
                      <font face="Verdana, serif">
                        <font
                          size="2"
                          style={{
                            "font-size": "9pt"
                          }}
                        >
                          <span lang="nl-NL">
                            voor fraude- en beveiligingscontrole, bijvoorbeeld
                            om cyberaanvallen of pogingen tot het plegen van
                            identiteitsdiefstal te detecteren en te voorkomen;
                          </span>
                        </font>
                      </font>
                    </p>
                  </li>
                  <li>
                    <p
                      className="western"
                      style={{
                        "margin-bottom": "0.08in"
                      }}
                    >
                      <font face="Verdana, serif">
                        <font
                          size="2"
                          style={{
                            "font-size": "9pt"
                          }}
                        >
                          <span lang="nl-NL">
                            voor het ontwikkelen van nieuwe producten en
                            diensten;
                          </span>
                        </font>
                      </font>
                    </p>
                  </li>
                  <li>
                    <p
                      className="western"
                      style={{
                        "margin-bottom": "0.08in"
                      }}
                    >
                      <font face="Verdana, serif">
                        <font
                          size="2"
                          style={{
                            "font-size": "9pt"
                          }}
                        >
                          <span lang="nl-NL">
                            voor het aanvullen, verbeteren of wijzigen van onze
                            website of onze producten en diensten;{" "}
                          </span>
                        </font>
                      </font>
                    </p>
                  </li>
                  <li>
                    <p
                      className="western"
                      style={{
                        "margin-bottom": "0.08in"
                      }}
                    >
                      <font face="Verdana, serif">
                        <font
                          size="2"
                          style={{
                            "font-size": "9pt"
                          }}
                        >
                          <span lang="nl-NL">
                            voor het identificeren van trends in het gebruik van
                            de Dienst, bijvoorbeeld om inzicht te krijgen in
                            welke onderdelen van onze Dienst het meest
                            interessant zijn voor gebruikers; en
                          </span>
                        </font>
                      </font>
                    </p>
                  </li>
                  <li>
                    <p
                      className="western"
                      style={{
                        "margin-bottom": "0.08in"
                      }}
                    >
                      <font face="Verdana, serif">
                        <font
                          size="2"
                          style={{
                            "font-size": "9pt"
                          }}
                        >
                          <span lang="nl-NL">
                            voor het bepalen van de doeltreffendheid van onze
                            promotiecampagnes, zodat we onze campagnes kunnen
                            aanpassen aan de behoeften en interesses van onze
                            gebruikers.
                          </span>
                        </font>
                      </font>
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
            <p
              className="western"
              lang="nl-BE"
              style={{
                "margin-left": "1in",
                "margin-bottom": "0.08in"
              }}
            >
              <br />
              &nbsp;
            </p>
            <p
              className="western"
              style={{
                "margin-left": "0.79in",
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    Wij zullen deze activiteiten uitvoeren om onze contractuele
                    relatie met u te beheren, om een wettelijke verplichting na
                    te komen, en/of omdat om onze gerechtvaardigd belangen te
                    behartigen.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              lang="nl-BE"
              style={{
                "margin-bottom": "0.08in"
              }}
            >
              <br />
              &nbsp;
            </p>
            <ul>
              <li>
                <p
                  className="western"
                  style={{
                    "-webkit-text-indent": "0in",
                    "text-indent": "0in",
                    "margin-bottom": "0.08in"
                  }}
                >
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                        <b>
                          Analyse van persoonlijke gegevens om gepersonaliseerde
                          diensten aan te bieden.
                        </b>
                      </span>
                    </font>
                  </font>
                </p>
                <ul>
                  <li>
                    <p
                      className="western"
                      style={{
                        "margin-bottom": "0.08in"
                      }}
                    >
                      <font face="Verdana, serif">
                        <font
                          size="2"
                          style={{
                            "font-size": "9pt"
                          }}
                        >
                          <span lang="nl-NL">
                            om u beter te begrijpen, zodat we onze interacties
                            met u kunnen personaliseren en u informatie en/of
                            aanbiedingen kunnen verstrekken die zijn afgestemd
                            op uw interesses;
                          </span>
                        </font>
                      </font>
                    </p>
                  </li>
                  <li>
                    <p
                      className="western"
                      style={{
                        "margin-bottom": "0.08in"
                      }}
                    >
                      <font face="Verdana, serif">
                        <font
                          size="2"
                          style={{
                            "font-size": "9pt"
                          }}
                        >
                          <span lang="nl-NL">
                            om uw voorkeuren beter te begrijpen, zodat we via de
                            Dienst content kunnen leveren die volgens ons
                            relevant en interessant voor u zal zijn.
                          </span>
                        </font>
                      </font>
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
            <p
              className="western"
              lang="nl-BE"
              style={{
                "margin-bottom": "0.08in"
              }}
            >
              <br />
              &nbsp;
            </p>
            <p
              className="western"
              style={{
                "margin-left": "0.79in",
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    Wij zullen gepersonaliseerde diensten verlenen met uw
                    toestemming of omdat om onze gerechtvaardige belangen te
                    behartigen.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              lang="nl-NL"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                widows: "0",
                orphans: "0"
              }}
            >
              <br />
              &nbsp;
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    Wij geven ook informatie die via de Dienst is verzameld
                    door:
                  </span>
                </font>
              </font>
            </p>
            <ul>
              <li>
                <p
                  className="western"
                  style={{
                    "margin-bottom": "0.08in",
                    widows: "0",
                    orphans: "0"
                  }}
                >
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                      aan onze filialen voor doeleinden beschreven in dit Privacybeleid. Een lijst van onze filialen is beschikbaar op{" "}
                      </span>
                    </font>
                  </font>
                  <a target="_blank"
                    className="western"
                    href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx"
                  >
                    <font face="Verdana, serif">
                      <font
                        size="2"
                        style={{
                          "font-size": "9pt"
                        }}
                      >
                        <span lang="nl-NL">
                          <u>
                          https://investors.kenvue.com/financials-reports/sec-filings/default.aspx
                          </u>
                        </span>
                      </font>
                    </font>
                  </a>
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                        {" "}
                        - toegang te krijgen tot de latere deponeringen van Kenvue bij de Securities Exchange Commission. Johnson &amp; Johnson Consumer N.V.
                      </span>
                    </font>
                  </font>
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                        <b> </b>
                      </span>
                    </font>
                  </font>
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                        is de partij die verantwoordelijk is voor het beheer van
                        de gezamenlijk gebruikte Persoonsgegevens;
                      </span>
                    </font>
                  </font>
                </p>
              </li>
              <li>
                <p
                  className="western"
                  style={{
                    "margin-bottom": "0.08in",
                    widows: "0",
                    orphans: "0"
                  }}
                >
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                        aan onze externe partners met wie wij gezamenlijk een
                        promotie aanbieden;
                      </span>
                    </font>
                  </font>
                </p>
              </li>
              <li>
                <p
                  className="western"
                  style={{
                    "margin-bottom": "0.08in",
                    widows: "0",
                    orphans: "0"
                  }}
                >
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                      aan onze externe dienstverleners die diensten leveren zoals websitehosting en -beheer, hosting van mobiele toepassingen, gegevensanalyse, betalingsverwerking, orderverwerking, aanbieden van infrastructuur, IT-diensten, klantenservices, e-mail en directe maildiensten, auditing en andere diensten om hen in staat te stellen diensten te leveren; en
                      </span>
                    </font>
                  </font>
                </p>
              </li>
              <li>
                <p
                  className="western"
                  style={{
                    "margin-bottom": "0.08in",
                    widows: "0",
                    orphans: "0"
                  }}
                >
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                      zoals toegelaten door de toepasselijke wetgeving, aan een externe partij in het geval van een reorganisatie, fusie, verkoop, joint venture, toewijzing, overdracht of andere afstoting van alle of een deel van ons bedrijf, onze activa of onze aandelen (ook in verband met een faillissement of vergelijkbare procedures).
                      </span>
                    </font>
                  </font>
                </p>
              </li>
            </ul>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Daarnaast gebruiken en geven we mogelijks uw informatie door voor zover wij dat noodzakelijk of geschikt achten: (a) om te voldoen aan een juridisch proces of de geldende wetgeving, waaronder mogelijk wetten buiten het land waar u woont; (b) om zoals toegelaten door de toepasselijke wetgeving aan verzoeken te voldoen van openbare en overheidsinstanties, waaronder mogelijk instanties buiten het land waar u woont; (c) om onze algemene voorwaarden te bekrachtigen; en (d) om de rechten, privacy, veiligheid of eigendom van ons, onze filialen, u of anderen te beschermen. We kunnen uw informatie ook gebruiken en doorgeven op andere manieren, nadat wij uw toestemming daartoe gekregen hebben.{" "}
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                widows: "0",
                orphans: "0"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  We kunnen informatie die we automatisch verzamelen gebruiken en doorgeven zoals eerder beschreven in het gedeelte ‘Automatische verzameling en gebruik van informatie’.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                widows: "0",
                orphans: "0"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Waar toegelaten door de toepasselijke wetgeving kunnen we daarnaast informatie die personen niet kan identificeren, gebruiken en doorgeven voor welk doeleinde dan ook. Als we informatie die niet persoonlijk identificeerbaar is combineren met informatie die wel identificeerbaar is (zoals het combineren van uw naam met uw geografische locatie) zullen wij de gecombineerde informatie behandelen als persoonsgegevens zolang als deze gecombineerd is.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>KEUZES EN TOEGANG</b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>
                      Uw keuzes met betrekking tot ons gebruik en doorgifte van
                      uw persoonsgegevens
                    </b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    Wij bieden u keuzes met betrekking tot ons gebruik en
                    doorgifte van uw persoonsgegevens voor marketingdoeleinden.
                    U kunt bezwaar maken tegen:
                  </span>
                </font>
              </font>
            </p>
            <ul>
              <li>
                <p
                  className="western"
                  style={{
                    "margin-bottom": "0.08in",
                    widows: "0",
                    orphans: "0"
                  }}
                >
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                        <b>Marketingcommunicatie ontvangen van ons</b>
                      </span>
                    </font>
                  </font>
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                      : Als u in de toekomst niet langer marketingcommunicatie van ons wilt ontvangen, kunt u bezwaar maken tegen de ontvangst ervan door contact met ons op te nemen via{" "}
                        <a
                          className="western"
                          href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p2"
                        >
                          contactformulier
                        </a>
                        . Vermeld in uw verzoek aan ons uw naam, geef de vorm(en) van marketingcommunicatie aan die u niet langer wenst te ontvangen en vermeld de adres(sen) waar deze naartoe wordt/worden gestuurd. Als u bijvoorbeeld niet langer marketing-e-mails of directe mail van ons wenst te ontvangen, kunt u dat aangeven.{" "}
                      </span>
                    </font>
                  </font>
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="de-DE">
                      Vermeld hierbij uw naam en e-mail of postadres. Daarnaast{" "}
                      </span>
                    </font>
                  </font>
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                      kunt u bezwaar maken tegen de ontvangst van marketing-e-mails door de instructies te volgen die in een dergelijk bericht gegeven worden.
                      </span>
                    </font>
                  </font>
                </p>
              </li>
              <li>
                <p
                  className="western"
                  style={{
                    "margin-bottom": "0.08in",
                    widows: "0",
                    orphans: "0"
                  }}
                >
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                        <b>Herinneringen ontvangen van ons</b>
                      </span>
                    </font>
                  </font>
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                        : Als u niet langer medische herinneringen van ons wilt
                        ontvangen, kunt u bezwaar maken tegen de ontvangst ervan
                        door contact met ons op te nemen via{" "}
                        <a
                          className="western"
                          href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p2"
                        >
                          contactformulier
                        </a>
                        . Vermeld in uw antwoord aan ons uw naam en e-mailadres
                        of telefoonnummer waarop u de herinneringen van ons
                        ontvangt.
                      </span>
                    </font>
                  </font>
                </p>
              </li>
            </ul>
            <p
              className="western"
              lang="nl-NL"
              style={{
                "margin-left": "0.5in",
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                widows: "0",
                orphans: "0"
              }}
            >
              <br />
              &nbsp;
            </p>
            <ul>
              <li>
                <p
                  className="western"
                  style={{
                    "margin-bottom": "0.08in",
                    widows: "0",
                    orphans: "0"
                  }}
                >
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                        <b>
                          Het delen van uw persoonsgegevens met filialen en
                          externe partners
                        </b>
                      </span>
                    </font>
                  </font>
                  <font face="Verdana, serif">
                    <font
                      size="2"
                      style={{
                        "font-size": "9pt"
                      }}
                    >
                      <span lang="nl-NL">
                      : Als u er eerder voor hebt gekozen om marketingcommunicatie te ontvangen van onze filialen of externe partners, kan u bezwaar maken tegen het delen van uw persoonsgegevens met die partijen voor hun direct-marketingdoelen door contact met ons op te nemen via{" "}
                        <a
                          className="western"
                          href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p2"
                        >
                          contactformulier
                        </a>
                        . Vermeld in uw antwoord aan ons dat wij niet langer uw persoonsgegevens mogen delen met onze filialen en/of externe partners voor hun marketingdoeleinden, en vermeld uw naam en e-mailadres.
                      </span>
                    </font>
                  </font>
                </p>
              </li>
            </ul>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Wij proberen zo snel als redelijk mogelijk aan uw verzoek(en) te voldoen. Wij wijzen u erop dat als u bezwaar maakt zoals hierboven beschreven, wij niet in staat zullen zijn om uw persoonsgegevens te verwijderen uit de databases van onze filialen waarmee wij reeds uw persoonsgegevens hebben gedeeld (d.w.z. vanaf de datum dat we uw verzoek tot bezwaar hebben verwerkt). We zullen echten redelijke inspanningen leveren om onze filialen op de hoogte te stellen van uw verzoek. Als u ervoor kiest geen marketinggerelateerde berichten meer van ons te ontvangen, kunnen wij u nog wel belangrijke transactionele en administratieve berichten sturen waartegen u geen bezwaar kan maken.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>
                      Hoe u inzage&nbsp;kunt krijgen van uw persoonsgegevens, ze
                      kan verbeteren of wissen
                    </b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Als u uw persoonsgegevens wilt nakijken, verbeteren, bijwerken, wissen of hun verwerking beperken, of als u een elektronische kopie van uw persoonsgegevens wil aanvragen om ze te kunnen doorsturen naar een ander bedrijf (in de mate dat de toepasselijke wetgeving u deze rechten toekent), neemt u contact met ons op via{" "}
                    <a
                      className="western"
                      href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p2"
                    >
                      contactformulier
                    </a>
                    . We zullen uw verzoek zo snel als redelijk mogelijk beantwoorden, en niet later dan een maand na ontvangst. Als omstandigheden een vertraging van ons antwoord veroorzaken, zal u onmiddellijk op de hoogte gebracht worden en een datum ontvangen waarop wij u zullen antwoorden. 
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>GRENSOVERSCHRIJDENDE OVERDRACHT</b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Uw persoonsgegevens kunnen in elk land waar we faciliteiten of dienstverleners hebben worden opgeslagen en verwerkt. Door onze Dienst te gebruiken of door ons toestemming te geven (waar wettelijk verplicht), kan uw informatie doorgegeven worden naar andere landen dan het land waar u woont, inclusief de Verenigde Staten, waar andere gegevensbeschermingwetten kunnen gelden dan in uw eigen land. Niettemin, gepaste contractuele en andere maatregelen zijn getroffen om persoonsgegevens te beschermen wanneer ze verzonden worden naar onze filialen of derden in andere landen.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Sommige landen buiten de Europese Economische Ruimte (EER) worden erkend door de Europese Commissie als landen waar een gepast niveau van gegevensbescherming geldt volgens de EER normen (de volledige lijst van deze landen is{" "}
                    <a
                      className="western"
                      href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_nl"
                    >
                      hier
                    </a>{" "}
                    beschikbaar). Voor doorgiften vanuit de EER naar landen die naar oordeel van de Europese Commissie niet voldoende bescherming bieden, hebben we verzekerd dat afdoende maatregelen getroffen werden, onder andere door te verzekeren dat de ontvanger gebonden is aan EU standaardbepalingen inzake gegevensbescherming, om uw persoonsgegevens te beschermen. U kan een kopie van deze maatregelen ontvangen door contact op te nemen met onze functionaris voor gegevensbescherming zoals uitgelegd in het onderstaande gedeelte 
                  </span>
                </font>
              </font>
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <i>‘Contact met ons opnemen’</i>
                  </span>
                </font>
              </font>
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">.</span>
                </font>
              </font>
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b> </b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>BEVEILIGING</b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Wij streven ernaar gebruik te maken van redelijke organisatorische, technische en administratieve maatregelen die ontworpen zijn om de persoonsgegevens in ons bezit te beschermen. Helaas kan voor geen enkele gegevensoverdracht over het internet noch voor enig gegevensopslagsysteem een 100% veiligheidsgarantie worden geboden. Als u redenen hebt om te geloven dat uw interactie met ons niet langer veilig is (bijvoorbeeld als u meent dat de beveiliging van een account die u bij ons heeft, is aangetast), breng ons daar dan onmiddellijk van op de hoogte, in overeenstemming met de onderstaande gedeelte{" "}
                  </span>
                </font>
              </font>
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <i>‘Contact met ons opnemen’</i>
                  </span>
                </font>
              </font>
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">.</span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>BEWAARPERIODE</b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  We zullen uw persoonsgegevens bewaren voor zo lang als nodig of toegelaten is voor het doel/de doelen waarvoor ze verkregen werden. De criteria die gebruikt worden om onze bewaarperiodes te bepalen, omvatten: (i) de duur van onze lopende relatie met u en van de Dienst die we u aanbieden; (ii) of er al dan niet een wettelijke verplichting is waaraan wij onderworpen zijn; en (iii) of bewaring al dan niet raadzaam is wegens onze juridische positie (zoals aangaande toepasselijke verjaringstermijn(en), een rechtszaak of onderzoeken van een toezichthouder).
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <a name="_Hlk511396473" />{" "}
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Als wij bijvoorbeeld uw persoonsgegevens verzamelen voor het afsluiten van een overeenkomst, zullen we deze gegevens voor een periode van 15 jaar vanaf afloop van het contract bewaren.{" "}
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    In gevallen dat u ons toestemming gegeven heeft u
                    marketing-informatie toe te doen laten komen slaan wij uw
                    gegevens op tot u deze toestemming intrekt resp. voor 5
                    jaar.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Houd er echter rekening mee dat dit geen alomvattende lijst van bewaarperiodes is. Uw persoonsgegevens kunnen voor een langere periode bewaard worden aan de hand van de criteria uit de eerste paragraaf van dit gedeelte, in het bijzonder punten (ii) en (iii).
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>WEBSITES EN DIENSTEN VAN DERDEN</b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Deze Dienst kan links bevatten naar sites van derden. Dit Privacybeleid is niet van toepassing op en wij zijn niet verantwoordelijk voor de privacy, informatie en praktijken van derden, inclusief derden die een site of online dienst exploiteren (inclusief, zonder beperking, alle toepassingen) die beschikbaar is via deze Dienst of waarnaar deze Dienst een link bevat. De beschikbaarheid van of het plaatsen van een link naar een dergelijke site of dienst op de Dienst houdt geen aanbeveling in van ons of onze filialen.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>CONTACT MET ONS OPNEMEN</b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    Johnson &amp; Johnson Consumer N.V. te Beerse, is het
                    bedrijf verantwoordelijk voor het verzamelen, het gebruik en
                    de doorgifte van persoonsgegevens volgens dit Privacybeleid.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    Neem als u vragen hebt over dit Privacybeleid contact op met
                    ons via{" "}
                    <a
                      className="western"
                      href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p2"
                    >
                      contactformulier
                    </a>{" "}
                    of schrijf naar het volgende adres:
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    Johnson &amp; Johnson Consumer N.V.
                    <br />
                    Antwerpseweg 15-17
                    <br />
                    B- 2340 Beerse
                    <br />
                    België
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    U kan ook contact opnemen met onze functionaris voor
                    gegevensbescherming die verantwoordelijk is voor uw land of
                    regio, indien van toepassing, via{" "}
                  </span>
                </font>
              </font>
              <a className="western" href="mailto:emeaprivacy@kenvue.com">
                <font face="Verdana, serif">
                  <font
                    size="2"
                    style={{
                      "font-size": "9pt"
                    }}
                  >
                    emeaprivacy@kenvue.com
                  </font>
                </font>
              </a>
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">.</span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>EEN KLACHT INDIEN BIJ EEN TOEZICHTHOUDER</b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                widows: "0",
                orphans: "0"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    U kan een klacht indienen bij een toezichthoudende
                    autoriteit die bevoegd is voor uw land of regio. Klik{" "}
                  </span>
                </font>
              </font>
              <a
                className="western"
                href="https://edpb.europa.eu/about-edpb/board/members_nl"
              >
                <font face="Verdana, serif">
                  <font
                    size="2"
                    style={{
                      "font-size": "9pt"
                    }}
                  >
                    <span lang="nl-NL">
                      <u>hier</u>
                    </span>
                  </font>
                </font>
              </a>
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    {" "}
                    voor de contactinformatie van dergelijke autoriteiten.
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0.08in",
                "page-break-after": "avoid"
              }}
            >
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                    <b>UPDATES VAN DIT PRIVACYBELEID</b>
                  </span>
                </font>
              </font>
            </p>
            <p
              className="western"
              style={{
                "-webkit-text-indent": "0in",
                "text-indent": "0in",
                "margin-bottom": "0in",
                widows: "0",
                orphans: "0"
              }}
            >
              <a name="_GoBack" />{" "}
              <font face="Verdana, serif">
                <font
                  size="2"
                  style={{
                    "font-size": "9pt"
                  }}
                >
                  <span lang="nl-NL">
                  Wij kunnen dit Privacybeleid wijzigen. Veranderingen in dit Privacybeleid treden in werking als wij het herziene Privacybeleid op de Dienst plaatsen. Uw gebruik van de Dienst na deze wijzigingen betekent dat u akkoord gaat met het herziene Privacybeleid. We bevelen aan dat u het Privacybeleid regelmatig naleest wanneer u de Dienst bezoekt. Dit beleid werd het laatst bijgewerkt op 04.05.2023.
                  </span>
                </font>
              </font>
            </p>
          </div>
        </div>
        <footer class="footer"
style={{"height": "501.006px"}}>
            <div class="row">
                <div class="three columns" style={{
                
              }}>
                    <ul>
                        <li class="first last" >
                        <a href="/hoe-werkt-imodium-instant/">Hoe werkt IMODIUM®?</a>
              
                        <ul>
                        <li class="first last">
                        <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">De werkzame stof: loperamide
                        </a>
                        </li>
                        </ul>
                        </li>
                    </ul>

                    <ul>
                        <li class="first last" >
                        <a href="/imodium-behandelen-diarree/">IMODIUM® Geneesmiddelen</a>
              
                          <ul>
                          <li class="first">
                          <a href="/imodium-behandelen-diarree/imodium-instant/">IMODIUM® Instant
                          </a></li>
                          <li>
                            <a href="/imodium-behandelen-diarree/imodium-duo/">IMODIUM® Duo
                          </a></li>
                          <li class="last">
                            <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">IMODIUM® Capsules
                          </a></li>
                          
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>
                        <li class="first last" >
                        <a href="/feiten-over-diarree/">Feiten over diarree</a>
              
                        <ul>
                        <li class="first"><a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">Tips voor behandeling</a></li>
                        <li><a href="/feiten-over-diarree/oorzaken-van-diarree/">Oorzaken van diarree</a></li>
                        <li><a href="/feiten-over-diarree/stress-en-diarree/">Stress en diarree</a></li>
                        <li><a href="/feiten-over-diarree/buikgriep/">Virale of bacteriële infectie</a></li>
                        <li><a href="/feiten-over-diarree/norovirus/">Norovirus</a></li>
                        <li><a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">Diarree tijdens de menstruatie</a></li>
                        <li><a href="/feiten-over-diarree/chronische-diarree/">Chronische Diarree</a></li>
                        <li><a href="/feiten-over-diarree/eenartsraadplegen/">Een	arts	raadplegen?</a></li>
                        <li class="last"><a href="/feiten-over-diarree/gezonde-eetgewoonten/">Gezonde eetgewoonten</a></li>
                        </ul>
                        </li>

                    </ul>
                </div>
                <div className="three columns" style={{
                
              }}>
                    
                    <ul>                        
                    </ul>
                    <ul>
                        <li class="first last" ><a href="/diarree-op-reis/">Diarree op reis</a>
                <ul>
                          <li class="first"><a href="/diarree-op-reis/reizigersdiaree-vermijden/">Reizigersdiarree vermijden</a></li>
                          <li><a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">Reizigersdiarree behandelen</a></li>
                          <li><a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a></li>
                          </ul>
                          </li>

                    </ul>
                </div>
                <div className="three columns" >
                    
                    <ul>
                        <li class="first"><a href="/diarree-bij-kinderen/">Diarree bij kinderen</a></li>
                        <li><a href="/infomateriaal-diaree/">Infomateriaal</a></li>
                        <li><a href="/woordenlijst/">Woordenlijst diarree</a></li>
                        <li class="last"><a href="/veelgestelde-vragen-diarree/">FAQ</a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="twelve columns">
                    <nav class="nav">
                        <ol class="uilist-hor">
                            <li class="first"><a href="/sitemap/">Sitemap</a></li>
                            <li><a href="/wettelijke-verklaring/">Wettelijke verklaring</a></li>
                            <li><a href="/privacybeleid/">Privacybeleid</a></li>
                            <li><a href="/cookiebeleid/">Cookiebeleid</a></li>
                            <li class="last"><a href="/contact/">Contact</a></li>
                            <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings" rel="nofollow">Cookie-instellingen</a>


                        </ol>
                    </nav>
                </div>
            </div>
            <div class="disclaimer">
                <div class="row">
                    <div class="twelve columns">
                        <p>© Johnson &amp; Johnson Consumer N.V. 2017, BTW BE 0401.955.033 - RPR Antwerpen, 2017. Deze site wordt onderhouden door Johnson &amp; Johnson Consumer N.V. die als enige verantwoordelijk is voor de inhoud. Deze site is bestemd voor een publiek uit België en G.H.Luxemburg. Last updated: Thursday, April 22, 2021 </p>
                        <p>IMODIUM<sup>®</sup> Instant / Capsules (loperamide) is een geneesmiddel. Niet toedienen aan kinderen jonger dan 6 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        IMODIUM<sup>®</sup> Duo (loperamidehydrochloride + simeticon ) is een geneesmiddel, niet toedienen aan kinderen jonger dan 12 jaar. Niet langer dan 2 dagen gebruiken zonder geneeskundig advies.
                        <br />
                        Lees aandachtig de bijsluiter. Vraag advies aan uw arts of apotheker. Vergunninghouder: Johnson &amp; Johnson Consumer N.V. Antwerpseweg 15-17, B-2340 Beerse. Dossiernr: BE-IM-2200031.
                        </p>
                   </div>
                </div>
            </div>
        </footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-nl.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
